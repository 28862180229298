import React from 'react';
import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';
import { Button, Layout, SEO } from '@componentsShared';
import { Wrapper } from '../../shared/components/atoms/containerGlobal/styled';
import { graphql, useStaticQuery } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import siteUrl from '@utils/siteUrl';

const ContainerNotFound = styled.div`
    position: relative;
    width: 90%;
    max-width: 400px;
    margin: 45px auto;
    border: 1px solid #0df96c;
    padding: 60px 20px;
    background-color: #1d1828;
    border-radius: 16px;

    ${breakpoint('lg')`
        max-width: 1100px;
        padding: 65px 40px;


      `}
`;

const Title = styled.h1`
    font-size: 96px;
    font-family: ${(props) => props.theme.fonts.mon_bold};
    -webkit-text-stroke: 1.5px white;
    color: #000000;
`;
const ContainerText = styled.div`
    margin: 30px 0 60px 0;
    p {
        font-size: 16px;
        line-height: 19px;
        font-family: ${(props) => props.theme.fonts.mon_extrabold};
    }
    p:first-child {
        margin-bottom: 20px;
    }
    b {
        color: ${(props) => props.theme.colors.accents.base.yellow};
    }
    ${breakpoint('lg')`
        width: 68%;


      `}
    p {
        ${breakpoint('lg')`
            font-size: 28px;
            line-height: 33.6px;


      `}
    }
`;

const ContainerButtons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 218px;
    margin: 0 auto;
    ${breakpoint('lg')`
        margin: 0;
     `}
`;

const TextLink = styled.a`
    cursor: pointer;
    text-decoration: none;
    font-family: ${(props) => props.theme.fonts.mon_bold};
    display: inline-block;
    font-size: 14px;
    color: #009f5c;
    &:hover {
        font-weight: bold;
    }
`;

const ImageContainer = styled.div`
    z-index: 1;
    position: absolute;
    bottom: 0;
    width: 600px;
    right: -150px;
    img {
        width: 100%;
        object-fit: contain;
        object-position: center;
    }
    display: none;
    ${breakpoint('lg')`
          display: initial;

     `}

    &:after {
        z-index: -1;
        content: '';
        position: absolute;

        width: 380px;
        height: 280px;
        right: 150px;
        bottom: 0;

        background: #0df96c;
        opacity: 0.2;
        filter: blur(130px);
        transform: matrix(1, 0, 0, -1, 0, 0);
    }
`;

const NotFoundPage = () => {
    const DataPageNotfound = useStaticQuery(graphql`
        query PageNotFound {
            allContentfulFilasPaginasFooter(filter: { title: { eq: "404" } }) {
                edges {
                    node {
                        id
                        title
                        text {
                            raw
                        }
                        mediaFile {
                            title
                            file {
                                url
                            }
                        }
                    }
                }
            }
        }
    `);

    const {
        allContentfulFilasPaginasFooter: { edges }
    } = DataPageNotfound;
    const { node } = edges[0];

    return (
        <Layout>
            <SEO
                title="404"
                description="No podemos encontrar la página que buscas ¡Pero no te preocupes, todavía puedes seguir apostando y jugando!"
                slug="/404"
            />
            <Wrapper>
                <ContainerNotFound>
                    <Title>{node.title}</Title>
                    <ContainerText>
                        {documentToReactComponents(JSON.parse(node.text.raw))}
                    </ContainerText>
                    <ContainerButtons>
                        <TextLink href={siteUrl + '/'}>Inicio</TextLink>
                        <Button
                            text="Juegos"
                            onClick={() => (window.location.href = siteUrl + '/juegos')}
                        ></Button>
                        <ImageContainer>
                            <img src={node.mediaFile.file.url} alt={node.mediaFile.title} />
                        </ImageContainer>
                    </ContainerButtons>
                </ContainerNotFound>
            </Wrapper>
        </Layout>
    );
};

export default NotFoundPage;
